import React, { useEffect } from "react";
import UserSidebar from "./userSidebar";
import { Row, Col } from "react-bootstrap";
import { Link, useLocation , useHistory} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserLayout = ({ children }) => {
  let location = useLocation();
  const history = useHistory();

  useEffect(()=>{
    const is_authenticated = localStorage.getItem('token');
    if(!is_authenticated){
      history.push('/');
    }
  },[location])

  return (
    <div className="layout">
      <Row>
        <Col className="d-none d-md-block" style={style.sidebar}>
          <UserSidebar />
        </Col>
        <Col style={{ padding: "0.5em" }}>
          <div className="article">{children}</div>
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
};
const style = {
  sidebar: {
    maxWidth: "210px",
    padding: 0,
    height: "100vh",
    position: "sticky",
    top: 0,
  },
};
export default UserLayout;
