import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Store from "./hooks/Store";
//Layouts
import UserLayout from "./utils/userLayout";

//Modules
const Login = lazy(() => import("./modules/Login"));
const ForgotPassword = lazy(() => import("./modules/ForgotPassword"));
const NotFound = lazy(() => import("./modules/NotFound"));
const Dashboard = lazy(() => import("./modules/Dashboard"));
const Clients = lazy(() => import("./modules/Clients"));
const Builders = lazy(() => import("./modules/Builders"));
const Packages = lazy(() => import("./modules/Packages"));
const Jsonfile = lazy(() => import("./modules/Jsonfile"));
const Release = lazy(() => import("./modules/Release"));
// const AllRelease = lazy(() => import("./modules/AllRelease"));
const UpdateClient = lazy(() => import("./modules/Clients/updateClient"));
const ClientDetails = lazy(() => import("./modules/Clients/clientDetails"));
const GoogleAcc = lazy(() => import("./modules/GoogleAcc"));
const SelectedGoogleAcc = lazy(() => import("./modules/GoogleAcc/selected_acc_details"));
const AllBuilds = lazy(() => import("./modules/AllRelease/allBuilds"));

const App = () => (
  <Store>
    <Router>
      <Suspense fallback={<div style={style.centerFlex}>Loading...</div>}>
        <Switch>
          <AppRoute exact path="/" component={Login}  />
          <AppRoute exact path="/forgotPassword" component={ForgotPassword}  />
          <AppRoute exact path="/dashboard" component={Dashboard} layout={UserLayout} />
          <AppRoute exact path="/builders" component={Builders} layout={UserLayout} />
          <AppRoute exact path="/packages" component={Packages} layout={UserLayout} />
          <AppRoute exact path="/jsonfile" component={Jsonfile} layout={UserLayout} />
          <AppRoute exact path="/clients" component={Clients} layout={UserLayout} />
          <AppRoute exact path="/clients/:clientId" component={ClientDetails} layout={UserLayout} />
          <AppRoute exact path="/clients/:clientId/update" component={UpdateClient} layout={UserLayout} />
          <AppRoute exact path="/release" component={Release} layout={UserLayout} />
          {/* <AppRoute exact path="/allReleases" component={AllRelease} layout={UserLayout} /> */}
          <AppRoute exact path="/playstoreAcc" component={GoogleAcc} layout={UserLayout} />
          <AppRoute exact path="/playstoreAcc/:selected_acc_details/:selected_google_acc_name" component={SelectedGoogleAcc} layout={UserLayout} />
          <AppRoute exact path="/allBuilds" component={AllBuilds} layout={UserLayout} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  </Store>
);

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => { 
      if(Layout && Layout !== ''){
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }
      else{
        return (
          <Component {...props} />
        )
      }
    }}
  />
);


const style = {
  centerFlex: {
    display: "flex",
    height: "100vh",
    width: "100vw",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
  },
};

export default App;