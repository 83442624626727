import React from "react";
import { Link, useLocation , useHistory} from "react-router-dom";
import "./style.css";

const menus = [
    {
        menu_id: 201,
        title: 'Dashboard',
        route: '/dashboard'
    },
    {
        menu_id: 202,
        title: 'Users',
        route: '/builders'
    },
    {
        menu_id: 203,
        title: 'Clients',
        route: '/clients'
    },
    {
        menu_id: 204,
        title: 'Build Release',
        route: '/release'
    },
    // {
    //     menu_id: 205,
    //     title: 'All Release',
    //     route: '/allReleases'
    // },
    {
      menu_id: 209,
      title: 'All Builds',
      route: '/allBuilds'
    },
    {
        menu_id: 206,
        title: 'JSON File Manager',
        route: '/jsonfile'
    },
    {
        menu_id: 207,
        title: 'Buffer Packages',
        route: '/packages'
    },
    {
      menu_id: 208,
      title: 'Google Account',
      route: '/playstoreAcc'
  },
];

export default function UserSidebar() {
  let location = useLocation();
  const history = useHistory();

  const user_type = localStorage.getItem('user_type');

  return (
    <div className="sidebar" style={style.sidebar}>
      <div className="logo-container">
        <div style={style.logo}>Proctur ARP</div>
      </div>
      <div className="menu-container">
        {
          menus.map((el)=>{
            return (
              <Link to={el.route} key={el.menu_id} 
                style={{ display: ((user_type == 2 && (el.menu_id == 202 || el.menu_id == 206 || el.menu_id == 208))?"none":"block") }}
                className={"sidebar-link " + (location.pathname === el.route ? "active" : "")}>
                {el.title}
              </Link>
            )
          })
        }

        <span key={0} className={"cursor-pointer sidebar-link"}
          onClick={()=>{localStorage.clear(); history.push("/");}}>
          Logout
        </span>
      </div>
    </div>
  );
}

const style = {
  sidebar: {
    background: "#003967",
    color: "#eee",
    height: "100%",
    width: "100%",
  },
  logo: {
    fontSize: "20px",
    letterSpacing: "2px",
    fontWeight: "500",
    textDecoration: "none",
    padding: "1em 1.5em 0.5em 1.5em",
  },
};
